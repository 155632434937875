<template>

  <div
      class="login login-signin-on login-3 d-flex flex-row-fluid"
      id="kt_login"
  >
    <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('/assets/media/bg/bg-3.jpg');"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">

        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMETHOD.PARTECIPAPROCESSO")}}</p>

        <div class="my-0">
            <img
              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
              style="max-height: 160px; max-width: 260px"
              alt="Logo"
            />
        </div>
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>

        <!--begin::Login Sign in form-->
        <div
            v-if="!this.requireConditions"
            class="login-signin">
          <div class="mb-2">
<!--            <div class="text-muted font-weight-bold">-->
<!--              <span class="h3"> {{$t("REGISTERPROCESSFROMANONYMOUS.TITOLO") /* #key_loc*/}}</span>-->
<!--            </div>-->
          </div>

          <h3 class="text-primary my-5 font-weight-bolder">{{$t("REGISTERPROCESSFROMANONYMOUS.ISCRIVITIPROCESSO")}} {{this.process.title}}</h3>
          <div
              v-if="getOrganization.data.registrationAllowed"
              class="text-muted font-weight-bold">{{$t("REGISTERPROCESSFROMANONYMOUS.NIENTEACCOUNT")}}
            <router-link
                :to="{
                  name: 'registerFromAnonymous',
                  params: {
                    process_id: process_id,
                  }
                }">
              <span class="text-primary font-weight-bolder">{{$t("REGISTERPROCESSFROMANONYMOUS.REGISTRATI")}}</span>
            </router-link>
          </div>

          <form class="form" @submit.stop.prevent="onSubmit">
            <div
                role="alert"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  placeholder="username"
                  name="username"
                  id="username"
                  v-model="$v.form.username.$model"
                  :state="validateState('username')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
              />
            </div>
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="password"
                  placeholder="Password"
                  id="password"
                  name="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
              />
            </div>
            <div
                v-if="false"
                class="d-flex justify-content-between mt-n5">
              <label class="font-size-h6 pt-5">{{$t("REGISTERPROCESSFROMANONYMOUS.NONRICORDIPASS")}}</label>
              <a href="/assets/email/request-password.html" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5">{{$t("REGISTERPROCESSFROMANONYMOUS.CAMBIAPASS")}}</a>
            </div>

            <div class="">
              <button
                  id="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder px-9 py-4 my-3 mx-4"
                  ref="kt_login_signin_submit"
              >
                {{$t("REGISTERPROCESSFROMANONYMOUS.ACCEDI")}}
              </button>
            </div>
          </form>
          <!-- <div class="mt-10">
            <span class="opacity-70 mr-4">Don't have an account yet?</span>
            <a href="javascript:;" id="kt_login_signup" class="text-muted text-hover-primary font-weight-bold">Sign Up!</a>
          </div> -->
        </div>
        <!--end::Login Sign in form-->

        <!--begin:: Require condition form -->
        <div  class="text-center" v-else>

          <h2 class="font-wight-bolder">{{$t("REGISTERPROCESSFROMANONYMOUS.UTENTEAUTENTICATO")}}</h2>
          <div style="max-width: 400px;" class="mt-5">
            <p class="lead" v-html='$t("REGISTERPROCESSFROMANONYMOUS.MEXLOGIN")'></p>
          </div>

          <form class="form" @submit.stop.prevent="onSubmitconditions">
            <div
                role="alert"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>

            <p v-if="!emailExists" class="font-weight-bolder">{{$t("REGISTERPROCESSFROMANONYMOUS.INSERISCIMAIL")}}</p>

            <div v-if="!emailExists" class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  placeholder="email"
                  name="email"
                  id="email"
                  v-model="email"
                  aria-describedby="email-live-feedback"
                  autocomplete="off"
              />
            </div>
            <p v-if="!emailExists" class="text-muted font-weight-bolder">{{$t("REGISTERPROCESSFROMANONYMOUS.NOTIFICHE")}}</p>

            <p class="text-muted text-left" v-html='$t("REGISTERPROCESSFROMANONYMOUS.TERMINICONDIZIONI")'></p>

            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <label class="checkbox m-0 text-muted">
                <input type="checkbox" name="term_and_conditions" v-model="term_and_conditions" />{{$t("REGISTERPROCESSFROMANONYMOUS.ACCETTO")}}
                <router-link
                    to="/terms"
                    target="_blank">{{$t("REGISTERPROCESSFROMANONYMOUS.TERMINI")}}
                </router-link>
                <span></span></label>
            </div>

            <div
                v-if="this.process.guidelines"
                class="form-group d-flex flex-wrap justify-content-between align-items-center pt-2 pb-2">
              <label class="checkbox m-0 text-muted">
                <input type="checkbox" name="process_guidelines" v-model="process_guidelines" />
                <span v-html='$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1")'></span>
                <a :href="'/process-guidelines/' + this.process.id"
                   target="_blank">
                  {{$t("GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2")}}</a>
              </label>
            </div>

            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <label class="checkbox m-0 text-muted">
                <input type="checkbox" name="privacy" v-model="privacy" />{{$t("REGISTERPROCESSFROMANONYMOUS.ACCETTOLA")}}
                <router-link
                    to="/privacy"
                    target="_blank">{{$t("REGISTERPROCESSFROMANONYMOUS.PRIVACY")}}
                </router-link>
                <span></span></label>
            </div>


            <button
                :disabled='isDisabled'
                id="kt_conditions_accepted_submit"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                ref="kt_login_conditions_accepted_submit"
            >
              {{$t("REGISTERPROCESSFROMANONYMOUS.BPROSEGUI")}}
            </button>

          </form>
        </div>
        <!--end:: Require condition form -->

        <!--begin::Login Sign up form
        <div class="login-signup">
          <div class="mb-20">
            <h3>Sign Up</h3>
            <div class="text-muted font-weight-bold">Enter your details to create your account</div>
          </div>
          <form class="form">
            <div class="form-group mb-5">
              <input
              class="form-control h-auto form-control-solid py-4 px-8"
              type="text"
              placeholder="username"
              name="username"
              id="username"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="username-live-feedback"
              />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" autocomplete="off" />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" />
            </div>
            <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Confirm Password" name="rpassword" />
            </div>
            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
              <input type="checkbox" name="agree" />I Agree the
              <a href="#" class="font-weight-bold">terms and conditions</a>.
              <span></span></label>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group d-flex flex-wrap flex-center mt-10">
              <button id="kt_login_signup_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Sign Up</button>
              <button id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
            </div>
          </form>
        </div>
        end::Login Sign up form-->
        <!--begin::Login forgot password form
        <div class="login-forgot">
          <div class="mb-20">
            <h3>Forgotten Password ?</h3>
            <div class="text-muted font-weight-bold">Enter your email to reset your password</div>
          </div>
          <form class="form">
            <div class="input-group mb-10">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" id="kt_email" autocomplete="off" />
            </div>
            <div class="form-group d-flex flex-wrap flex-center mt-10">
              <button id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">Request</button>
              <button id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button>
            </div>
          </form>
        </div>
        end::Login forgot password form-->
      </div>
    </div>
  </div>
  <!--end::Login-->
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import {
  LOGIN,
  SET_HEADER_TOKEN
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import store from "@/core/services/store";
import {processMixin} from "@/mixins/process";

export default {
  mixins: [validationMixin, processMixin],
  name: "registerProcessFromAnonymous",
  data() {
    return {
      // errors: [],
      requireConditions: false,
      term_and_conditions: false,
      process_guidelines:false,
      privacy:false,
      emailExists: false,
      email: '',
      process_id: this.$route.params.process_id,
      process: [],
      // Remove this dummy login info
      form: {
        username: "",
        password: "",
        process_id: this.process_id
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
    isDisabled: function(){
      if( this.process.guidelines && this.process.guidelines != "") {
        return !this.process_guidelines;
      } else {
        return false;
      }
    }
  },
  mounted() {},
  async created() {
    this.process = await this.getProcess(this.process_id) ;
    this.process_guidelines = this.process.guidelines && this.process.guidelines.length >0;
    console.log('Linee guida:', this.process_guidelines);
    // setTimeout(() => {
    //   // let odata = this.getOrganization.data;
    //   console.log('twoLevelLogin: ', this.getOrganization.data.twoLevelLogin );
    // }, 500);
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      },
    },
  },
  methods: {
    validateEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onSubmitconditions() {

      if (this.email && !this.validateEmail(this.email)) {
        Swal.fire(this.$t("REGISTERPROCESSFROMANONYMOUS.TITLE2"), this.$t("REGISTERPROCESSFROMANONYMOUS.ERR4"), "info");
        return false;
      }

      // Aggiorno il profilo dell'utente con le accettazioni
      setTimeout(() => {
        let acceptData = {
          "id": this.currentUser.id,
          "termsConditionsAccepted": true,
          "privacyPolicyAccepted": true,
          "email": this.email
        }
        new Promise((resolve, reject) => {
          ApiService.put(this.$apiResource + "/users/mandatory-flags", acceptData)
              .then(() => {
                console.log('registrato');
                resolve(1);
              })
              .catch(() => {
                reject(0);
              });
        });

        // Invio l'utente alla home
        this.$router.push({ name: "intro" });
        Swal.fire(this.$t("REGISTERPROCESSFROMANONYMOUS.TITLE1"),this.$t("REGISTERPROCESSFROMANONYMOUS.MEX1") , 'info')
      }, 500);

    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let credentials = {
        username: username,
        password: password,
        // selectedOrganizationId: 1,
        selectedOrganizationDomain: this.$currentDomain,
        fetchEnabledOnly: true
      };

      // L'utente carmelo vede anche i processi disabilitati
      if (username == "carmelo") {
        // credentials.fetchEnabledOnly = false;
      }

      let loginResult = await this.$store.dispatch(LOGIN, credentials);
      submitButton.classList.remove("spinner", "spinner-light", "spinner-right");

      if ( loginResult.headers.token == "BadCredentialsException" ) {
        Swal.fire(this.$t("REGISTERPROCESSFROMANONYMOUS.TITLE2"), this.$t("REGISTERPROCESSFROMANONYMOUS.ERR5"), "info");
      } else {

        // Swal.fire('Success', 'Autenticazione riuscita', 'success')
        // this.$router.push({name: "home"});
        /* */
        const userId = jwt_decode(loginResult.headers.token).id;

        await new Promise((resolve, reject) => {
          // setTimeout( () => {
          store.dispatch(SET_HEADER_TOKEN)
          var endpoint = this.generateUrl("/users/mandatory-flags/" + userId, false);
          //generateUrl is done
          ApiService.get(this.$apiResource + endpoint)
              .then(res => {
                if (
                    ( res.data.privacyPolicyAccepted
                        && res.data.termsConditionsAccepted )
                    ||
                    !this.getOrganization.data.twoLevelLogin
                ) {
                  Swal.fire(this.$t("REGISTERPROCESSFROMANONYMOUS.TITLE1"), this.$t("REGISTERPROCESSFROMANONYMOUS.MEX2"), 'success')
                  this.$router.push({name: "intro"});
                } else {
                  this.emailExists = (res.data.email) ? true : false;
                  this.email = res.data.email;
                  this.requireConditions = true;
                }
              })
              .catch(() => {
                Swal.fire(this.$t("REGISTERPROCESSFROMANONYMOUS.TITLE3"), this.$t("REGISTERPROCESSFROMANONYMOUS.ERR3"), 'error')
                reject(0);
              });

          // }, 500);
        });
        /* */
      }
    },
  }
};
</script>
